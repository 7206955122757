/* eslint-env es6, browser, commonjs, node */
import 'c3/c3.css';
import './css/kiosk.css';
import './assets/ecogy-logo.png';

// theme support: pass THEME=X environment variable to build to include a specific theme
require(`../themes/${process.env.THEME}/logo-header.png`);
require(`../themes/${process.env.THEME}/header-background.png`);
require(`../themes/${process.env.THEME}/theme.css`);

import 'c3';
import KioskApp from './js/kiosk-app.js';

const theme = {
	title: `${process.env.TITLE}`,
	colors: {
		consumption: `${process.env.COLOR_CONSUMPTION}`,
		generation: `${process.env.COLOR_GENERATION}`,
	},
	nodeIds: `${process.env.NODE_IDS}`.split(','),
	sourceIds: {
		consumption: (process.env.SOURCE_IDS_CONSUMPTION ? `${process.env.SOURCE_IDS_CONSUMPTION}`.split(',') : []),
		generation: (process.env.SOURCE_IDS_GENERATION ? `${process.env.SOURCE_IDS_GENERATION}`.split(',') : []),
	},
	co2factor: (!isNaN(Number(process.env.CO2_FACTOR)) ? process.env.CO2_FACTOR : '1.099'),
	generationPowerMaxValue: (!isNaN(Number(process.env.MAX_POWER_GENERATION)) ? process.env.MAX_POWER_GENERATION : '4000'),
};

if ( !window.isLoaded ) {
	window.addEventListener('load', function() {
		KioskApp.startApp(theme);
	}, false);
} else {
	KioskApp.startApp(theme);
}
