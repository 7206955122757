/* eslint-env es6, browser, commonjs */
import Kiosk from "./kiosk";
import {
  Configuration,
  displayScaleForValue,
  displayUnitsForScale,
  Environment,
  NodeDatumUrlHelper,
  urlQuery
} from "solarnetwork-api-core";
import c3 from "c3";
import { max, permute, sum } from "d3-array";
import { format } from "d3-format";
import { scaleUtc } from "d3-scale";
import { select, selectAll } from "d3-selection";

var app;

class KioskApp {
  /**
   * Constructor.
   *
   * @param {Configuration} [config] the configuration to use
   * @param {Environment} [env] the environment to use; will default to one constructed from `window.location`
   */
  constructor(config, env) {
    Object.defineProperties(this, {
      /**
       * The class version.
       *
       * @memberof KioskApp
       * @readonly
       * @type {string}
       */
      version: { value: "1.0.0" }
    });

    /**
     * The app configuration to use.
     * @type {Configuration}
     */
    this.config = config || new Configuration();

    /**
     * The SolarNetwork environment to use.
     * @type {Environment}
     */
    this.env = env || new Environment();

    /**
     * @type {Kiosk}
     * @private
     */
    this.kiosk = new Kiosk(this.config, new NodeDatumUrlHelper(this.env));

    /**
     * The refresh frequency for minute aggregate data, in milliseconds.
     * @type {number}
     */
    this.minuteDataRefreshMs = 1 * 60 * 1000;

    /**
     * A timer reference resulting from a call to `setTimeout` in order to refresh the minute aggregate data.
     * @type {*}
     * @private
     */
    this.minuteDataRefreshTimer = null;

    /** @private */
    this.minuteDataChart = null;

    /** @private */
    this.generationGaugeChart = null;

    /**
     * The refresh frequency for hour aggregate data, in milliseconds.
     * @type {number}
     */
    this.hourDataRefreshMs = 2 * 60 * 1000;

    /**
     * A timer reference resulting from a call to `setTimeout` in order to refresh the hour aggregate data.
     * @type {*}
     * @private
     */
    this.hourDataRefreshTimer = null;

    /** @private */
    this.hourDataChart = null;

    /**
     * The refresh frequency for day aggregate data, in milliseconds.
     * @type {number}
     */
    this.dayDataRefreshMs = 5 * 60 * 1000;

    /**
     * A timer reference resulting from a call to `setTimeout` in order to refresh the day aggregate data.
     * @type {*}
     * @private
     */
    this.dayDataRefreshTimer = null;

    /** @private */
    this.dayDataChart = null;

    /**
     * The refresh frequency for month aggregate data, in milliseconds.
     * @type {number}
     */
    this.monthDataRefreshMs = 10 * 60 * 1000;

    /**
     * A timer reference resulting from a call to `setTimeout` in order to refresh the month aggregate data.
     * @type {*}
     * @private
     */
    this.monthDataRefreshTimer = null;

    /** @private */
    this.monthDataChart = null;
  }

  /**
   * The desired size (in pixels) for the main level charts.
   * @type {number}
   */
  get mainChartHeight() {
    return this.config.mainChartHeight || 424;
  }

  /**
   * The desired size (in pixels) for the sub level charts.
   * @type {number}
   */
  get subChartHeight() {
    return this.config.subChartHeight || 220;
  }

  /**
   * The maximum power for generation data, in watts.
   * @type {number}
   */
  get generationPowerMaxValue() {
    return Number(this.config.generationPowerMaxValue) || 4000;
  }

  /**
   * The minimum power for generation data, in watts.
   *
   * This is useful when a power meter is used to monitor the generation output of
   * a PV inverter, but the inverter itself draws power during the night creating
   * negative effective power values. Rather than display negative "generation" values
   * this setting can force them to `0`.
   *
   * @type {number}
   */
  get generationPowerMinValue() {
    return Number(this.config.generationPowerMinValue) || 0;
  }

  get consumptionCombinedSourceId() {
    return this.kiosk.consumptionCombinedSourceId;
  }

  get generationCombinedSourceId() {
    return this.kiosk.generationCombinedSourceId;
  }

  /**
   * Create a new C3 area chart configuration for timeseries data.
   *
   * @param {object[]} c3data the chart data in a form suitable for passing to C3 as JSON
   * @param {string} target the CSS selector to render the chart into
   * @param {number} tickCount the approximate number of ticks desired
   * @param {number} height the desired height of the chart, in pixels
   * @returns {object} a configuration suitable for passing to `c3.generate()`
   * @private
   */
  areaTimeseriesChartConfig(c3data, target, tickCount, height) {
    const timeScale = scaleUtc();
    if (Array.isArray(c3data) && c3data.length > 0) {
      timeScale.domain([c3data[0].date, c3data[c3data.length - 1].date]);
    }
    const chartConfig = {
      bindto: target,
      data: {
        json: c3data,
        keys: {
          x: "date",
          value: [
            this.consumptionCombinedSourceId,
            this.generationCombinedSourceId
          ]
        },
        type: "area-spline",
        colors: this.config.colors
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            values: timeScale.ticks(tickCount),
            format: timeScale.tickFormat(tickCount)
          }
        },
        y: {
          tick: {
            //format: function (d) { return d; }
          }
        }
      },
      legend: {
        show: false
      },
      point: {
        show: false
      },
      size: {
        height: height
      }
    };
    return chartConfig;
  }

  /**
   * Create a new C3 pie chart configuration for timeseries data.
   *
   * @param {object[]} c3data the chart data in a form suitable for passing to C3 as JSON
   * @param {string} target the CSS selector to render the chart into
   * @param {number} height the desired height of the chart, in pixels
   * @returns {object} a configuration suitable for passing to `c3.generate()`
   * @private
   */
  pieChartConfig(c3data, target, height) {
    const chartConfig = {
      bindto: target,
      data: {
        json: c3data,
        keys: {
          value: [
            this.consumptionCombinedSourceId,
            this.generationCombinedSourceId
          ]
        },
        type: "pie",
        colors: this.config.colors
      },
      legend: {
        show: false
      },
      pie: {
        label: {}
      },
      size: {
        height: height
      }
    };
    return chartConfig;
  }

  /**
   * Create a new C3 guage chart configuration for timeseries data.
   *
   * @param {object[]} c3data the chart data in a form suitable for passing to C3 as JSON
   * @param {string} target the CSS selector to render the chart into
   * @param {number} height the desired height of the chart, in pixels
   * @returns {object} a configuration suitable for passing to `c3.generate()`
   * @private
   */
  gaugeChartConfig(c3data, target, height) {
    const chartConfig = {
      bindto: target,
      data: {
        json: c3data,
        keys: {
          value: [this.generationCombinedSourceId]
        },
        type: "gauge",
        colors: this.config.colors
      },
      legend: {
        show: false
      },
      gauge: {
        label: {
          show: false
        },
        max: this.generationPowerMaxValue
      },
      size: {
        height: Math.ceil(height / 1.5)
      }
    };
    return chartConfig;
  }

  /**
   * Update the shown units for a DOM selection.
   *
   * This method will replace the content of all elements with a `unit` class with the display unit
   * for the given scale.
   *
   * @param {d3.Selection} selection the container within which to update the time units
   * @param {string} baseUnit the base unit, e.g. `W` for watts
   * @param {number} scale the display scale, e.g. `1000` for kilo
   * @returns {void}
   */
  adjustDisplayUnits(selection, baseUnit, scale) {
    var unit = displayUnitsForScale(baseUnit, scale);
    selection.selectAll(".unit").text(unit);
  }

  /**
   * Update the shown time units for a DOM selection.
   *
   * Call this to update the DOM to match the configured time range used for a chart, for example.
   *
   * @param {d3.Selection} selection the container within which to update the time units
   * @param {object} range the range object with `timeCount` and `timeUnit` properties
   * @returns {void}
   */
  adjustTimeUnits(selection, range) {
    selection.selectAll(".time-count").text(range.timeCount);
    selection.selectAll(".time-unit").text(range.timeUnit.name);
  }

  /**
   * Determine a display scale and appropriate value format to use for a set of data.
   *
   * @param {object[]} data the data to extract a max value from
   * @param {string[]} metricKeys the data property names to treat as metric values
   * @returns {object} object with a `displayScale` number and `format` function that accepts a number and returns a formatted string
   * @private
   */
  displayFormatForData(data, metricKeys) {
    const maxValue = !data
      ? 0
      : max(data, d => {
          return max(permute(d, metricKeys).map(Math.abs));
        });
    const displayScale = displayScaleForValue(maxValue);
    const displayFormat = format(
      (function() {
        var fmt;
        if (displayScale >= 1000000) {
          fmt = ",.2f";
        } else if (displayScale === 1000) {
          fmt = ",.1f";
        } else if (displayScale === 1) {
          fmt = ",d";
        } else {
          fmt = ",g";
        }
        return fmt;
      })()
    );
    return {
      displayScale: displayScale,
      format: function(d) {
        return displayFormat(d / displayScale);
      }
    };
  }

  /**
   * Render HTML table rows for a chart legend.
   *
   * @param {string} containerSelector a CSS selector for the container of the table rows (e.g. a `tbody` element)
   * @param {c3.chart} chart the chart to render a legend for
   * @param {string[]} sources the chart source IDs to show
   * @returns {void}
   */
  createColorDataLegendTable(containerSelector, chart, sources) {
    // add labels based on available sources
    const rows = select(containerSelector)
      .selectAll("tr")
      .data(sources)
      .enter()
      .append("tr");

    const labelRenderer = function(s) {
      s.text(Object);
    };

    rows
      .selectAll("td.swatch")
      .data(function(d) {
        return [chart.color(d)];
      })
      .style("background-color", Object)
      .enter()
      .append("td")
      .attr("class", "swatch")
      .style("background-color", Object);
    rows
      .selectAll("td.desc")
      .data(function(d) {
        return [d];
      })
      .call(labelRenderer)
      .enter()
      .append("td")
      .attr("class", "desc")
      .call(labelRenderer);
  }

  /**
   * Refresh the generation gauge chart.
   *
   * @param {object[]} c3data the combined chart data; the highest array item only is used
   * @returns {void}
   */
  refreshGenerationGauge(c3data) {
    var gaugeData =
      Array.isArray(c3data) && c3data.length > 0
        ? Object.create(
            c3data
              .filter(d => {
                return d[this.generationCombinedSourceId] !== undefined;
              })
              .slice(-1)
          )
        : [{}];
    var minGenValue = this.generationPowerMinValue;
    if (
      !gaugeData[0][this.generationCombinedSourceId] ||
      (minGenValue !== undefined &&
        gaugeData[0][this.generationCombinedSourceId] < minGenValue)
    ) {
      gaugeData[0][this.generationCombinedSourceId] =
        minGenValue !== undefined ? minGenValue : 0;
    }

    const displayFormat = this.displayFormatForData(
      [{ foo: this.generationPowerMaxValue }],
      ["foo"]
    );
    console.info(
      `Current generation power is ${
        gaugeData[0][this.generationCombinedSourceId]
      } (${displayFormat.format(
        gaugeData[0][this.generationCombinedSourceId]
      )} ${displayUnitsForScale("W", displayFormat.displayScale)}) - ${(
        (gaugeData[0][this.generationCombinedSourceId] /
          this.generationPowerMaxValue) *
        100
      ).toFixed(1)}%`
    );
    if (!this.generationGaugeChart) {
      const chartConfig = this.gaugeChartConfig(
        gaugeData,
        "#power-gauge-chart",
        this.subChartHeight
      );
      chartConfig.gauge.label.format = displayFormat.format;
      this.generationGaugeChart = c3.generate(chartConfig);
    } else {
      this.generationGaugeChart.load({
        json: gaugeData,
        keys: {
          value: [this.generationCombinedSourceId]
        }
      });
    }
    this.adjustDisplayUnits(
      selectAll(".power-gauge-chart"),
      "W",
      displayFormat.displayScale
    );
  }

  async refreshMinuteData() {
    const data = await this.kiosk.loadMinuteAggregateData();
    const range = this.kiosk.rangeForMinuteAggregateData();
    const c3data = this.kiosk.generationConsumptionChartData(
      data,
      "watts",
      range.aggregate
    );
    const yDisplayFormat = this.displayFormatForData(c3data, [
      this.consumptionCombinedSourceId,
      this.generationCombinedSourceId
    ]);

    // apply generationPowerMinValue to generation data
    const minGenValue = this.generationPowerMinValue;
    if (minGenValue !== undefined) {
      c3data.forEach(d => {
        if (d[this.generationCombinedSourceId] < minGenValue) {
          d[this.generationCombinedSourceId] = minGenValue;
        }
      });
    }

    if (!this.minuteDataChart) {
      const chartConfig = this.areaTimeseriesChartConfig(
        c3data,
        "#power-minute-chart",
        12,
        this.mainChartHeight
      );
      chartConfig.axis.y.tick.format = yDisplayFormat.format;
      this.minuteDataChart = c3.generate(chartConfig);
      this.createColorDataLegendTable(
        "#legend table tbody",
        this.minuteDataChart,
        [this.consumptionCombinedSourceId, this.generationCombinedSourceId]
      );
    } else {
      this.minuteDataChart.load({
        json: c3data,
        axes: {
          y: {
            tick: {
              format: yDisplayFormat.format
            }
          }
        },
        keys: {
          x: "date",
          value: [
            this.consumptionCombinedSourceId,
            this.generationCombinedSourceId
          ]
        }
      });
    }
    this.adjustDisplayUnits(
      selectAll(".power-minute-chart"),
      "W",
      yDisplayFormat.displayScale
    );
    this.refreshGenerationGauge(c3data);
  }

  async refreshHourData() {
    const data = await this.kiosk.loadHourAggregateData();
    const range = this.kiosk.rangeForHourAggregateData();
    const c3data = this.kiosk.generationConsumptionChartData(
      data,
      "wattHours",
      range.aggregate
    );
    const yDisplayFormat = this.displayFormatForData(c3data, [
      this.consumptionCombinedSourceId,
      this.generationCombinedSourceId
    ]);
    if (!this.hourDataChart) {
      const chartConfig = this.areaTimeseriesChartConfig(
        c3data,
        "#energy-hour-chart",
        12,
        this.mainChartHeight
      );
      chartConfig.axis.y.tick.format = yDisplayFormat.format;
      this.hourDataChart = c3.generate(chartConfig);
    } else {
      this.hourDataChart.load({
        json: c3data,
        axes: {
          y: {
            tick: {
              format: yDisplayFormat.format
            }
          }
        },
        keys: {
          x: "date",
          value: [
            this.consumptionCombinedSourceId,
            this.generationCombinedSourceId
          ]
        }
      });
    }
    this.adjustDisplayUnits(
      selectAll(".energy-hour-chart"),
      "Wh",
      yDisplayFormat.displayScale
    );
  }

  async refreshDayData() {
    const data = await this.kiosk.loadDayAggregateData();
    const c3data = this.kiosk.generationConsumptionChartData(data, "wattHours");
    const yDisplayFormat = this.displayFormatForData(c3data, [
      this.consumptionCombinedSourceId,
      this.generationCombinedSourceId
    ]);
    if (!this.dayDataChart) {
      const chartConfig = this.pieChartConfig(
        c3data,
        "#energy-day-chart",
        this.subChartHeight
      );
      chartConfig.pie.label.format = yDisplayFormat.format;
      this.dayDataChart = c3.generate(chartConfig);
    } else {
      this.dayDataChart.load({
        json: c3data,
        keys: {
          value: [
            this.consumptionCombinedSourceId,
            this.generationCombinedSourceId
          ]
        }
      });
    }
    this.adjustDisplayUnits(
      selectAll(".energy-day-chart"),
      "Wh",
      yDisplayFormat.displayScale
    );
  }

  async refreshMonthData() {
    const data = await this.kiosk.loadMonthAggregateData();
    const c3data = this.kiosk.generationConsumptionChartData(data, "wattHours");
    const yDisplayFormat = this.displayFormatForData(c3data, [
      this.consumptionCombinedSourceId,
      this.generationCombinedSourceId
    ]);
    if (!this.monthDataChart) {
      const chartConfig = this.pieChartConfig(
        c3data,
        "#energy-month-chart",
        this.subChartHeight
      );
      chartConfig.pie.label.format = yDisplayFormat.format;
      this.monthDataChart = c3.generate(chartConfig);
    } else {
      this.monthDataChart.load({
        json: c3data,
        keys: {
          value: [
            this.consumptionCombinedSourceId,
            this.generationCombinedSourceId
          ]
        }
      });
    }
    this.adjustDisplayUnits(
      selectAll(".energy-month-chart"),
      "Wh",
      yDisplayFormat.displayScale
    );
  }

  updateOdometer(selector, total) {
    const totalCharacters = format(",d")(total).split("");

    var odo = select(selector)
      .selectAll("span")
      .data(totalCharacters);
    odo.exit().remove();
    odo = odo
      .enter()
      .append("span")
      .attr("class", "digit")
      .merge(odo)
      .classed("digit", function(d) {
        return !isNaN(Number(d));
      })
      .classed("sep", function(d) {
        return isNaN(Number(d));
      })
      .text(function(d) {
        return d;
      });
    //document.getElementById('energy-running-total').innerText = Math.round(total / 1000); // force kWh
  }

  async refreshRunningTotalData() {
    const data = await this.kiosk.loadRunningTotalAggregateData();
    const c3data = this.kiosk.generationConsumptionChartData(data, "wattHours");
    const total =
      sum(c3data, d => {
        return d[this.generationCombinedSourceId];
      }) / 1000;
    this.updateOdometer("#energy-running-total", total);
    this.updateOdometer(
      "#co2-running-total",
      Math.round(this.kiosk.co2factor * total)
    );
  }

  scheduleRefreshMinuteData() {
    this.minuteDataRefreshTimer = setTimeout(() => {
      this.refreshMinuteData();
      if (this.minuteDataRefreshTimer) {
        this.scheduleRefreshMinuteData();
      }
    }, this.minuteDataRefreshMs);
  }

  scheduleRefreshHourData() {
    this.hourDataRefreshTimer = setTimeout(() => {
      this.refreshHourData();
      this.refreshRunningTotalData();
      if (this.hourDataRefreshTimer) {
        this.scheduleRefreshHourData();
      }
    }, this.hourDataRefreshMs);
  }

  scheduleRefreshDayData() {
    this.dayDataRefreshTimer = setTimeout(() => {
      this.refreshDayData();
      if (this.dayDataRefreshTimer) {
        this.scheduleRefreshDayData();
      }
    }, this.dayDataRefreshMs);
  }

  scheduleRefreshMonthData() {
    this.monthDataRefreshTimer = setTimeout(() => {
      this.refreshMonthData();
      if (this.monthDataRefreshTimer) {
        this.scheduleRefreshMonthData();
      }
    }, this.monthDataRefreshMs);
  }

  start() {
    selectAll("section.sub").style(
      "display",
      Array.isArray(this.config.generationSourceIds) &&
      this.config.generationSourceIds.length > 0
        ? null
        : "none"
    );
    if (!this.minuteDataRefreshTimer) {
      this.refreshMinuteData();
      this.scheduleRefreshMinuteData();
      let range = this.kiosk.rangeForMinuteAggregateData();
      this.adjustTimeUnits(selectAll(".minute-chart"), range);
    }
    if (!this.hourDataRefreshTimer) {
      this.refreshHourData();
      this.refreshRunningTotalData();
      this.scheduleRefreshHourData();
      let range = this.kiosk.rangeForHourAggregateData();
      this.adjustTimeUnits(selectAll(".hour-chart"), range);
    }
    if (!this.dayDataRefreshTimer) {
      this.refreshDayData();
      this.scheduleRefreshDayData();
      let range = this.kiosk.rangeForDayAggregateData();
      this.adjustTimeUnits(selectAll(".day-chart"), range);
    }
    if (!this.monthDataRefreshTimer) {
      this.refreshMonthData();
      this.scheduleRefreshMonthData();
      let range = this.kiosk.rangeForMonthAggregateData();
      this.adjustTimeUnits(selectAll(".month-chart"), range);
    }
  }

  stop() {
    if (this.minuteDataRefreshTimer) {
      clearTimeout(this.minuteDataRefreshTimer);
      this.minuteDataRefreshTimer = null;
    }
    if (this.hourDataRefreshTimer) {
      clearTimeout(this.hourDataRefreshTimer);
      this.hourDataRefreshTimer = null;
    }
    if (this.dayDataRefreshTimer) {
      clearTimeout(this.dayDataRefreshTimer);
      this.dayDataRefreshTimer = null;
    }
    if (this.monthDataRefreshTimer) {
      clearTimeout(this.monthDataRefreshTimer);
      this.monthDataRefreshTimer = null;
    }
  }

  /**
   * Configure and start the kiosk application.
   *
   * @param {Object} theme theme properties
   * @returns {void}
   */
  static startApp(theme) {
    this.setupTheme(theme);
    if (!app) {
      let nodeIds =
        Array.isArray(theme.nodeIds) && theme.nodeIds.length
          ? theme.nodeIds
          : [108];
      let conSourceIds =
        theme.sourceIds &&
        Array.isArray(theme.sourceIds.consumption) &&
        theme.sourceIds.consumption.length
          ? theme.sourceIds.consumption
          : [];
      let genSourceIds =
        theme.sourceIds &&
        Array.isArray(theme.sourceIds.generation) &&
        theme.sourceIds.generation.length
          ? theme.sourceIds.generation
          : [];
      let colors = {
        Consumption:
          theme.colors && theme.colors.consumption
            ? theme.colors.consumption
            : "#0f88f0",
        Generation:
          theme.colors && theme.colors.generation
            ? theme.colors.generation
            : "#f7c819"
      };
      let co2factor = theme.co2factor || "1.099";
      let generationPowerMaxValue = theme.generationPowerMaxValue || "4000";
      let config = new Configuration(
        Object.assign(
          {
            nodeIds: nodeIds,
            generationSourceIds: genSourceIds,
            generationCombinedSourceId: "Generation",
            consumptionSourceIds: conSourceIds,
            consumptionCombinedSourceId: "Consumption",
            colors: colors,
            co2factor: co2factor,
            generationPowerMaxValue: generationPowerMaxValue
          },
          urlQuery.urlQueryParse(
            window.location.search,
            new Set(["nodeIds", "generationSourceIds", "consumptionSourceIds"])
          )
        )
      );
      app = new KioskApp(config);
    }
    app.start();
  }

  static setupTheme(theme) {
    if (!theme) {
      return;
    }
    if (theme.title) {
      selectAll(".theme-title").text(theme.title);
    }
  }
}

export default KioskApp;
