import {
	Aggregations,
	arrayData,
	Configuration,
	dateUtils,
	DatumFilter,
	nestData,
	NodeDatumUrlHelper,
} from 'solarnetwork-api-core';
import {
	DatumLoader
} from 'solarnetwork-datum-loader';

class Kiosk {

	/**
	 * Constructor.
	 * 
	 * @param {Configuration} config the configuration to use
	 * @param {NodeDatumUrlHelper} urlHelper the URL helper
	 * @param {DatumFilter} [filter] the query filter; will default to one constructed from `config` properties
	 */
	constructor(config, urlHelper, filter) {
		Object.defineProperties(this, {
			/**
			 * The class version.
			 * 
			 * @memberof Kiosk
			 * @readonly
			 * @type {string}
			 */
			version: { value: '1.0.0' }
		});

		/**
		 * The kiosk configuration to use.
		 * @type {Configuration}
		 */
		this.config = (config || new Configuration());

		/** @type {NodeDatumUrlHelper} */
		this.urlHelper = (urlHelper || new NodeDatumUrlHelper());

		/** @type {DatumFilter} */
		this.filter = (filter || this.createDatumFilter());
	}

	/**
	 * The set of node IDs to load data from.
	 * @type {number[]}
	 */
	get nodeIds() {
		return this.config.nodeIds || [];
	}

	/**
	 * The set of source IDs to treat as "generation" data.
	 * @type {string[]}
	 */
	get generationSourceIds() {
		return this.config.generationSourceIds || [];
	}

	/**
	 * The set of source IDs to treat as "consumption" data.
	 * @type {string[]}
	 */
	get consumptionSourceIds() {
		return this.config.consumptionSourceIds || [];
	}

	/**
	 * The number of hours to query for minute-level aggregate data.
	 * @type {number}
	 */
	get minuteAggregateHourCount() {
		return this.config.minuteAggregateHourCount || 24;
	}

	/**
	 * The number of days to query for hour-level aggregate data.
	 * @type {number}
	 */
	get hourAggregateDayCount() {
		return this.config.hourAggregateDayCount || 7;
	}

	/**
	 * The number of months to query for day-level aggregate data.
	 * @type {number}
	 */
	get dayAggregateMonthCount() {
		return this.config.dayAggregateMonthCount || 1;
	}

	/**
	 * The number of years to query for month-level aggregate data.
	 * @type {number}
	 */
	get monthAggregateYearCount() {
		return this.config.monthAggregateYearCount || 1;
	}

	/**
	 * A source ID to group generation sources into.
	 * @type {string}
	 */
	get generationCombinedSourceId() {
		return this.config.generationCombinedSourceId || 'Generation';
	}

	/**
	 * A source ID to group consumption sources into.
	 * @type {string}
	 */
	get consumptionCombinedSourceId() {
		return this.config.consumptionCombinedSourceId || 'Consumption';
	}

	/**
	 * A CO2 factor to use, for deriving CO2 saved from Wh.
	 * @type {number}
	 */
	get co2factor() {
		return Number(this.config.co2factor) || 1.099;
	}

	/**
	 * Create a new DatumFilter out of the configuration on this class.
	 * 
	 * @returns {DatumFilter} the datum filter
	 */
	createDatumFilter() {
		const sources = new Set(this.generationSourceIds.concat(this.consumptionSourceIds));
		return new DatumFilter({
			nodeIds: this.nodeIds,
			sourceIds: Array.from(sources)
		});
	}

	loaderForAggregateDataRange(range) {
		const f = new DatumFilter(this.filter);
		f.aggregation = range.aggregate;
		f.endDate = range.end;
		f.startDate = range.start;
		return new DatumLoader(this.urlHelper, f);
	}

	rangeForMinuteAggregateData() {
		return dateUtils.rollingQueryDateRange(Aggregations.TenMinute, {numHours:this.minuteAggregateHourCount});
	}

	loaderForMinuteAggregateData() {
		const range = this.rangeForMinuteAggregateData();
		return this.loaderForAggregateDataRange(range);
	}

	async loadMinuteAggregateData() {
		return this.loaderForMinuteAggregateData().fetch();
	}

	rangeForHourAggregateData() {
		return dateUtils.rollingQueryDateRange(Aggregations.Hour, {numDays:this.hourAggregateDayCount});
	}

	loaderForHourAggregateData() {
		const range = this.rangeForHourAggregateData();
		return this.loaderForAggregateDataRange(range);
	}

	async loadHourAggregateData() {
		return this.loaderForHourAggregateData().fetch();
	}

	rangeForDayAggregateData() {
		return dateUtils.rollingQueryDateRange(Aggregations.Day, {numMonths:this.dayAggregateMonthCount});
	}

	loaderForDayAggregateData() {
		const range = this.rangeForDayAggregateData();
		return this.loaderForAggregateDataRange(range);
	}

	async loadDayAggregateData() {
		return this.loaderForDayAggregateData().fetch();
	}

	rangeForMonthAggregateData() {
		return dateUtils.rollingQueryDateRange(Aggregations.Month, {numYears:this.monthAggregateYearCount});
	}

	loaderForMonthAggregateData() {
		const range = this.rangeForMonthAggregateData();
		return this.loaderForAggregateDataRange(range);
	}

	async loadMonthAggregateData() {
		return this.loaderForMonthAggregateData().fetch();
	}

	loaderForRunningTotalAggregateData() {
		const f = new DatumFilter(this.filter);
		f.aggregation = Aggregations.RunningTotal;
		return new DatumLoader(this.urlHelper, f);
	}

	async loadRunningTotalAggregateData() {
		return this.loaderForRunningTotalAggregateData().fetch();
	}

	/**
	 * Transform raw SolarNetwork data into a form suitable for charting on a generation vs. consumption chart.
	 * 
	 * @param {object[]} data the raw data returned from SolarNetwork
	 * @param {string} metricName the datum property name to extract
	 * @param {Aggregation} [aggregate] if provided, an aggregate to normalize the data time series to
	 * @returns {object[]} array of datum objects, each with a date, generation value, and consumption value
	 */
	generationConsumptionChartData(data, metricName, aggregate) {
		const sourceMapping = new Map();
		for ( let srcId of this.generationSourceIds ) {
			sourceMapping.set(srcId, this.generationCombinedSourceId);
		}
		for ( let srcId of this.consumptionSourceIds ) {
			sourceMapping.set(srcId, this.consumptionCombinedSourceId);
		}
		const result = nestData.groupedBySourceMetricDataArray(data, metricName, sourceMapping);
		if ( aggregate && result ) {
			arrayData.timeNormalizeDataArray(result, aggregate);
		}
		return result || [];
	}

}

export default Kiosk;